var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-skeleton-loader',{style:({ height: '100%', width: '100%' }),attrs:{"loading":_vm.loadings.list,"type":"card-heading, image@3"}},[(_vm.segmentList.length)?_c('v-container',{staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"title-m-bold neutral-900--text"},[_vm._v(" Сегменты товаров и услуг ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetchData}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $iconify_feather-refresh-ccw ")]),_c('span',[_vm._v("Обновить")])],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.clickCreate}},[_c('iconify-icon',{staticStyle:{"margin-right":"8px"},attrs:{"icon":"plus-circle","width":"21"}}),_c('p',{staticClass:"body-m-semibold mb-0 neutral-100--text"},[_vm._v(" Новый сегмент ")])],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.segmentList.length)?_c('base-table',{attrs:{"class-name":"table-segment","headers":_vm.tableHeaders,"items":_vm.tableItems,"word-operations":['сегмент', 'сегмента', 'сегментов'],"loading":_vm.loadings.list,"item-class":function () { return 'clickable-row'; }},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('segment-chip',{attrs:{"name":item.name,"color":item.color,"chipAuto":item.type == 'auto',"max-width":"380px","chip":""}})]}},{key:"item.nomenclatures_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nomenclatures_count || 0)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","loading":item.action}},on),[_c('v-icon',[_vm._v("$iconify_feather-more-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.getItemActions(item)),function(menuItem,index){return _c('v-list-item',{key:index,on:{"click":function($event){return menuItem.action(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"neutral-500"}},[_vm._v(" "+_vm._s(menuItem.icon)+" ")])],1),_c('v-list-item-title',{class:{
                      'body-s-medium' : true,
                      'neutral-500--text':true,
                    }},[_vm._v(" "+_vm._s(menuItem.title)+" ")])],1)}),1)],1)]}}],null,true)}):_vm._e()],1)],1)],1):_c('base-empty-block-page',{attrs:{"title":"Здесь пока ничего нет :’("},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Сегментируйте ваши товары и услуги, чтобы быстрее находить нужные. ")]},proxy:true},{key:"image",fn:function(){return [_c('v-img',{attrs:{"src":require("@/assets/png/settings-dummy.png"),"width":"193.96px","height":"174px"}})]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"d-block",staticStyle:{"margin":"0 auto"},attrs:{"color":"primary"},on:{"click":_vm.clickCreate}},[_c('iconify-icon',{staticStyle:{"margin-right":"8px"},attrs:{"icon":"carbon-diagram","height":"21"}}),_vm._v(" Создать сегмент ")],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }