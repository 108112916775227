<template>
  <v-skeleton-loader
    :loading="loadings.list"
    :style="{ height: '100%', width: '100%' }"
    type="card-heading, image@3"
  >
    <v-container
      v-if="segmentList.length"
      fluid
      style="height: 100%;"
    >
      <v-row>
        <v-col class="title-m-bold neutral-900--text">
          Сегменты товаров и услуг
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="fetchData"
          >
            <v-icon left>
              $iconify_feather-refresh-ccw
            </v-icon>
            <span>Обновить</span>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            @click="clickCreate"
          >
            <iconify-icon
              style="margin-right: 8px;"
              icon="plus-circle"
              width="21"
            />
            <p
              class="body-m-semibold mb-0 neutral-100--text"
            >
              Новый сегмент
            </p>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <base-table
            v-if="segmentList.length"
            class-name="table-segment"
            :headers="tableHeaders"
            :items="tableItems"
            :word-operations="['сегмент', 'сегмента', 'сегментов']"
            :loading="loadings.list"
            :item-class="() => 'clickable-row'"
            @click:row="clickRow"
          >
            <template v-slot:[`item.name`]="{ item }">
              <segment-chip
                :name="item.name"
                :color="item.color"
                :chipAuto="item.type == 'auto'"
                max-width="380px"
                chip
              />
            </template>
            <template v-slot:[`item.nomenclatures_count`]="{ item }">
              {{ item.nomenclatures_count || 0 }}
            </template>
            <!-- <template v-slot:[`item.actions`]="{ item }">
              <div class="item__actions">
                <v-btn
                  class="btn-action-edit"
                  color="neutral-600"
                  text
                  :loading="loadings.editItemId == item.id"
                  :disabled="Boolean(loadings.editItemId && loadings.editItemId != item.id)"
                  @click="clickEdit(item)"
                >
                  <v-icon
                    class="icon neutral-600--text"
                    size="21"
                  >
                    $iconify_feather-edit
                  </v-icon>
                </v-btn>
                <v-btn
                  class="btn-action-delete"
                  text
                  color="neutral-600"
                  :loading="loadings.deleteItems[item.id]"
                  @click="clickDelete(item)"
                >
                  <v-icon
                    class="icon neutral-600--text"
                    size="21"
                  >
                    $iconify_feather-trash
                  </v-icon>
                </v-btn>
              </div>
            </template> -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    small
                    :loading="item.action"
                    v-on="on"
                  >
                    <v-icon>$iconify_feather-more-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(menuItem, index) in getItemActions(item)"
                    :key="index"
                    @click="menuItem.action(item)"
                  >
                    <v-list-item-icon>
                      <v-icon color="neutral-500">
                        {{ menuItem.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title
                      :class=" {
                        'body-s-medium' : true,
                        'neutral-500--text':true,
                      }"
                    >
                      {{ menuItem.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </base-table>
        </v-col>
      </v-row>
    </v-container>
    <base-empty-block-page
      v-else
      title="Здесь пока ничего нет :’("
    >
      <template v-slot:description>
        Сегментируйте ваши товары и услуги, чтобы быстрее находить нужные.
      </template>
      <template v-slot:image>
        <v-img
          src="@/assets/png/settings-dummy.png"
          width="193.96px"
          height="174px"
        />
      </template>
      <template v-slot:actions>
        <v-btn
          class="d-block"
          style="margin: 0 auto"
          color="primary"
          @click="clickCreate"
        >
          <iconify-icon
            style="margin-right: 8px"
            icon="carbon-diagram"
            height="21"
          />
          Создать сегмент
        </v-btn>
      </template>
    </base-empty-block-page>
  </v-skeleton-loader>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import SegmentChip from '@/components/segment/SegmentChip'
  import Vue from 'vue'

  export default {
    components: {
      SegmentChip,
    },
    data () {
      return {
        loadingEditItemId: false,
        loadings: {
          list: false,
          editItemId: null,
          deleteItems: {},
        },
        tableHeaders: [
          { text: 'ID', align: 'start', value: 'id' },
          { text: 'Название', align: 'start', value: 'name' },
          { text: 'Тип', align: 'start', value: 'type_label' },
          { text: 'Товары и услуги', align: 'end', value: 'nomenclatures_count' },
          { text: 'Действия', value: 'actions', align: 'end', sortable: false },
        ],
      }
    },
    computed: {
      ...mapGetters({
        programId: 'programId',
        segmentList: 'company/nomen_segment/list',
      }),
      tableItems () {
        return this.segmentList.map(item => {
          Vue.set(item, 'type_label', item.type === 'manual' ? 'Ручной' : 'Автоматический')
          Vue.set(item, 'action', false)
          return item
        })
      },

    },
    watch: {
      programId (v) {
        if (v) this.fetchData()
      },
    },
    mounted () {
      this.fetchData()
    },
    methods: {
      ...mapActions({
        fetchList: 'company/nomen_segment/getList',
        fetchDeleteSegment: 'company/nomen_segment/delete',
        recalcSegment: 'company/nomen_segment/recalc',
      }),
      clickRow (item) {
        this.$router.push({ name: 'NomenclatureList', query: { search: '#' + item.name } })
      },
      clickCreate () {
        this.$router.push({ name: 'NomenclatureSegmentsMaster' })
      },
      async clickEdit (item) {
        this.$router.push({ name: 'NomenclatureSegmentsEdit', params: { id: item.id } })
      },
      async clickDelete (item) {
        try {
          item.action = true
          this.$set(this.loadings.deleteItems, item.id, true)
          await this.fetchDeleteSegment(item.id)
          this.$set(this.loadings.deleteItems, item.id, false)
        } finally {
          item.action = false
        }
      },
      async fetchData () {
        try {
          this.loadings.list = true
          const programId = this.programId
          await this.fetchList({ programId })
        } finally {
          this.loadings.list = false
        }
      },
      async refreshSegment (item) {
        try {
          item.action = true
          await this.recalcSegment(item.id)
        } finally {
          item.action = false
        }
      },
      getItemActions (item) {
        return [
          {
            icon: '$iconify_feather-refresh-ccw',
            title: 'Обновить',
            action: this.refreshSegment,
            show: item.type === 'auto',
          },
          {
            icon: '$iconify_feather-edit',
            title: 'Редактировать',
            action: this.clickEdit,
            show: true,
          },
          {
            icon: '$iconify_feather-trash',
            title: 'Удалить',
            action: this.clickDelete,
            show: !item.deleted_at,
          },
        ].filter(x => x.show)
      },
    },
  }
</script>

<style lang="scss" scoped>

.item__actions {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  .btn-action-edit, .btn-action-delete {
    min-width: 0;
    width: 35px;
    height: 35px!important;
    border-radius: 50%;
    align-self: center;
  }
}

</style>
